.single-post{
	@media print{
		body{
			-webkit-print-color-adjust: exact;
			background-color: white;
			font-size: 12pt;
		}
		// a:link:after,
		// a:visited:after {
		// 	content: " (" attr(href) ") ";
		// 	font-size: 90%;
		// }
		a[href^="http"]:after {
			content: " (" attr(href) ") ";
		}
		a[href^="mailto"]:after {
			content: " (" attr(href) ") ";
		}

		.site-header                    { display: none; }
		.page-wrap{
			.main-article{
				.img-wrapper{
					img{
						margin: 0!important;
					}
					.caption-toggle         { display: none; }
				}
				.vignette{
					border: 1pt solid black;
					page-break-inside: avoid;
				}
				footer{
					.social-media       { display: none; }
					#comments           { display: none; }
				}
				.related-articles{
					border: 1pt solid black;
					page-break-inside: avoid;
					margin: 0!important;
					ul{
						padding: 0;
						li{
							&:before{ display: none; }
						}
					}
				}
			}
			.read-also                  { display: none; }
			#main--feed                 { display: none; }
			aside.sidebar{
				&:not(.sidebar--article){ display: none; }
				&.sidebar--article{
					border: 1pt solid black;
					page-break-inside: avoid;
				}
				.about1, .about2{
					border: none;
				}
			}
		}
		.site-footer                    { display: none; }
		#catapult-cookie-bar            { display: none; }
		@page{
			size: A4;
			h1, h2, h3, h4, h5, h6 {
				page-break-after: avoid;
			}
		}
	}
}