/* exemple styles */

.news-carrousel__control__list__link,
.images-carrousel__control__list__link {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: #fff;
  border: 1px solid #666;
  border-radius: 1em;
}

.news-carrousel__control__list__link:focus, .news-carrousel__control__list__link:hover, .news-carrousel__control__list__link:active, .news-carrousel__control__list__link[aria-selected=true], .images-carrousel__control__list__link:focus, .images-carrousel__control__list__link:hover, .images-carrousel__control__list__link:active, .images-carrousel__control__list__link[aria-selected=true] {
  background: #666;
}

.images-carrousel__button-container {
  position: absolute;
  bottom: 12px;
  z-index: 200;
  @include media-breakpoint-down(xs){
    background-color: rgba(0,0,0,.5);
    border-radius: 0;
    padding: 5px;
    bottom: initial;
    top: calc(50% - 18px);
  }
}
.news-carrousel__button__previous,
.images-carrousel__button__previous {
  left: 25px;
  @include media-breakpoint-down(xs){
    left: 0;
  }
}
.news-carrousel__button__next,
.images-carrousel__button__next {
  right: 25px;
  @include media-breakpoint-down(xs){
    right: 0;
  }
}
.news-carrousel__button__button,
.images-carrousel__button__button {
  height: auto;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  opacity: 0.8;
  &:hover {
    background: transparent;
    opacity: 1;
  }

  >img {
    height: 26px;
    vertical-align: middle;
  }
}






/* ------------------ transition fade ------------------ */
.fade .carrousel__content {
  animation: fadein 1s;
  white-space: normal;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
}
.fade .carrousel__content[aria-hidden=true] {
  animation: fadeout 1s;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

@keyframes fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


//** NEW STYLING *//


.page-slider {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  z-index: 1;
  @include clearfix();

  .article-meta {
    margin-bottom: $padding;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.page-slider__content {
  width: 100%;
  position: relative;
  padding: 20px;
  background: $color-ice;
  box-sizing: border-box;

  .link {
    @include cover-parent;
  }

  $triangle-size-s: 15px;
  $triangle-size: 15px;
  &:after{
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    z-index: 1;

    border-left: $triangle-size-s solid transparent;
    border-right: $triangle-size-s solid transparent;
    border-bottom: $triangle-size-s solid $color-ice;
    top: -#{$triangle-size-s - 1};
    left: 50%;
    @include transform(translateX(-50%));
  }

  @include media-breakpoint-up(md) {
    width: 33.33%;
    float: left;
  }

  @include media-breakpoint-up(sm) {
    width: 50%;
    float: left;
    &:after{
      border: none;
      top: initial;
      left: initial;

      border-top: $triangle-size solid transparent;
      border-bottom: $triangle-size solid transparent;
      border-left: $triangle-size solid $color-ice;
      right: -#{$triangle-size - 1};
      top: 50%;
      @include transform(translateY(-50%));
    }
  }

  >h1 {
    font-size: 20px;
    line-height: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 32px;
    }

    a {
      color: $color-black;
    }
  }

  .slider-pre {
    font-family: $font-headline;
    @include media-breakpoint-up(sm) {
      display: none;
    }
    @include media-breakpoint-up(md) {
      display: block;
      font-size: 14px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;

    }
  }

  .slider-meta {
    @include media-breakpoint-up(md) {

      position: absolute;
      bottom: 0px;
    }
  }
}

.carrousel,
.page-slider__no-img {
  width: 100%;
  position: relative;
  float: left;
  line-height: 0;
  background: $color-blackish;

  @include media-breakpoint-up(sm) {
    order: 2;
    width: 50%;
  }
  @include media-breakpoint-up(md) {
    width: 66.66%;
  }
}
.article--single{
  .carrousel,
  .page-slider__no-img{
    width: calc(100% + 20px);
    margin: 0 -10px;
    @include media-breakpoint-up(md) {
      width: 100%;
      margin: 0;
    }
  }
  .photo-meta{
    margin-bottom: 20px;
    position: relative;
    .byline{
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      max-width: 28%;
    }
    .caption{
      margin-right: 30%;
      text-align: left;
      text-transform: none;
      line-height: 1.1em;
      color: $color-black;
      font-family: $font-headline;
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.carrousel__container {
  white-space: nowrap;
  background: $color-ice;
  height: 0px;
  padding-bottom: 56.25%; /* 16:9 */

}
.carrousel__content[aria-hidden=true] {
  display: none;
}

.carrousel__content .caption {
  position: absolute;
  right: 0;
  bottom: 60px;
  padding: 10px 25px 10px 10px;
  z-index: 100;
  text-transform: none;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  @include media-breakpoint-down(xs){
    margin-bottom: 30px;
    bottom: 0;
  }
}

.carrousel__content img,
.page-slider__no-img img {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.images-carrousel__control__list {
  position: absolute;
  z-index: 100;
  height: 50px;
  list-style-type: none;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  @include media-breakpoint-down(xs){
    text-align: right;
    background-color: transparent;
    padding: 0 5px;
    height: 40px;
    li{
      margin: 0 .4em;
    }
  }
}

.images-carrousel__control__list__item {
  display: inline-block;
  margin: 0 .5em;
}
.images-carrousel__control__list__link {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $color-blackish;
  }

  &[aria-selected=true] {
    background-color: $color-red;
  }
}
