#comments {
  clear: both;
  font-family: $font-body-alt;

  #commentform {
    .author, .email, .form-submit, .comment-notes {
      display: none;
    }

    &.show-inputs {
      .author, .email, .form-submit, .comment-notes {
        display: block;
      }
    }
  }

  &.hide-comments {
    ol.comment-list, ol.children {
      li:nth-of-type(1n+4) {
        display: none;
      }
    }

    #hide-comments {
      display: none;
    }

    #show-comments {
      display: initial !important;
    }
  }

  textarea {
    height: 70px;
    font-size: 13px;
    max-width: 100%;
    max-height: 500px;
  }

  input[type="text"] {
    font-size: 13px;
  }


  ol.comment-list, ol.children {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .comment-info {
    @include clearfix;
    .author, .comment-date {
      float: left;
      height: $padding;
      margin: 0px;
      font-size: 13px;
      font-family: $font-headline;
      font-weight: bold;
      color: $color-black;
    }
    .comment-date {
      float: right;
      font-weight: 500;
    }
  }

  .comment {
    padding: $padding/2 0;
    border-bottom: 1px solid $color-light;
    font-family: $font-headline;
    font-size: 13px;
    @include clearfix;
  }

  .comment-content p:last-of-type{
    margin-bottom: 0px;

  }

  .reply {
    text-align: right;
  }

  .comment-respond {
    background: $color-light;
    padding: $padding/2;
    margin-bottom: $padding;
    @include media-breakpoint-up(lg) {
      padding: $padding/2 $padding;
    }
  }

  .toggle-comments {
    margin: $padding 0;
    text-align: center;
      #show-comments {
        display: none;
      }
    button {
      font-size: 13px;
    }
    
  }

  @include media-breakpoint-up(lg) {

    .comment-info {
      width: 25%;
      box-sizing: border-box;
      float: left;
      padding: 0px 10px;
      .author, .comment-date {
        float: none;
      }
    }
    .comment-content {
      width: 75%;
      float: right;
      box-sizing: border-box;

      p:first-of-type{
        margin-top: 0px;
      }
    }

    .toggle-comments button {
      font-size: 20px;
    }
  }
}
