.sidebar {
  @include media-breakpoint-up(md) {
    padding-top: 0px;
  }

  .author-img {
    padding-top: 0px;
    .photo-byline {
      @include media-breakpoint-between(sm, md) {
          padding-right: 10px;
      }
    }
  }
}

.sidebar__item {
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;

  .sidebar__item__link {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 0px;
    margin-left: 20px;
    margin-top: 20px;
  }

  h3 {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  blockquote {
    margin: 0;
    font-family: $font-headline-alt;
    font-size: 22px;
    line-height: 1.3;
    @include media-breakpoint-up(md) {
      font-size: 30px;
    }
  }

  .byline {
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: $font-headline;
    font-size: $font-super-tiny;
    font-weight: bold;
    text-align: right;
  }

  .sidebar__number {
    width: 100%;
    font-family: $font-headline;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: white;
    &.s{
      font-size: 140px;
      @include media-breakpoint-between(sm, md){
        font-size: 100px;
      }
    }
    &.m{
      font-size: 100px;
      @include media-breakpoint-between(sm, md){
        font-size: 70px;
      }
    }
    &.l{
      font-size: 60px;
      @include media-breakpoint-between(sm, md){
        font-size: 45px;
      }
    }
  }

  .sidebar__headline {
    margin: 20px 0;
    line-height: 1.2;
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }

  .soruce {
    text-align: right;
    color: $color-black;
  }

  p {
    .intro {
      color: white;
      font-weight: 500;
    }
    font-family: $font-headline;
    font-weight: normal;
  }

  img {
    max-width: 100%;
  }

  &.poff {
    border-top: 20px solid $color-yellow;
  }
  &.number {
    background-color: $color-peach;

    .link-icon {
      height: .8em;
      display: inline;
      position: relative;
      top: 0.1em;
      margin-right: 15px;
    }

    color: $color-black;
  }
  &.simple {
    p {
      font-weight: normal;

      .intro {
        color: $color-blue;
        font-weight: 500;
      }
    }
  }
  &.ad{
    padding: 0;
    .ad-headline{
      margin-bottom: 5px;
      font-family: $font-headline;
      font-size: $font-super-tiny;
      font-weight: 500;
    }

    &.ad-250x600 {
        img {
            max-width: 250px;
            width: 100%;
            max-height: 600px;
        }
    }

    &.ad-160x600 {
        img {
            max-width: 160px;
            width: 100%;
            max-height: 600px;
        }
    }
  }
  &.grey, &.info1, &.form2 {
    background-color: $color-light;
  }
  &.info2, &.blue, &.contact{
    background-color: $color-ice;
  }
  &.contact {
    h3 {
      text-align: left;
    }
  }
}

.sidebar--page, .sidebar--article {
  .sidebar__item {
    text-align: left;

    h3 {
      // font-family: $font-headline;
      // text-transform: none;
      // text-align: left;
      // font-weight: 500;
      // font-size: 24px;
      // letter-spacing: 0;

      &:after {
        display: block;
        content: "";
        width: 30px;
        height: 3px;
        background-color: $color-black;
        margin-top: 5px;
      }
    }
    .text-red{
      color: $color-red;
    }
    .text-bold, .text-bold p{
      font-weight: bold;
    }
  }

  .info1{
    h3:after{
      display: none;
    }
    .sidebar__headline{
      font-family: $font-headline-robot;
      font-size: 22px;
      font-weight: normal;
      text-transform: uppercase;
    }
    a[href]{
      color: inherit;
      border-bottom: 1px solid $color-red;
      padding-bottom: 3px;
      cursor: pointer;
    }
  }
  .info2{
    h3:after{
      display: none;
    }
    .sidebar__headline{
      font-family: $font-headline;
      font-size: 30px;
      font-weight: 600;
    }
  }
  .form1, .form2{
    .sidebar__headline{
      font-family: $font-headline-robot;
      font-size: 22px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  .contact{
    .sidebar__headline{
      font-size: 20px;
      font-weight: 500;
      &:after {
        display: block;
        content: "";
        width: 30px;
        height: 3px;
        background-color: $color-black;
        margin-top: 5px;
      }
    }
  }

  .form1,
  .form2,
  .about1,
  .about2,
  .sidebar__item--tags{
    font-family: $font-headline;

    h3 {
      // font-family: $font-headline;
      // margin-top: 5px;
      // margin-bottom: 0;
      // font-size: 24px;
      // line-height: 1.3;

      @extend .box-heading;

      &:after {
        display: none;
      }
    }
    p {
      margin-top: 5px;
    }
    .fscf-div-form {
      margin-top: 25px;
    }
  }
  .about1 {
    border: 1px solid #ccc;
  }
  .form1,
  .about2 {
    background-color: $color-yellow;
    color: $color-black;
  }
  .about1,
  .about2 {
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        margin-bottom: 7px;
        &:before {
          display: inline-block;
          content: "";
          width: 15px;
          height: 12px;
          margin-right: 15px;
          background-image: url('../images/arrow-black-list.svg');
          background-size: cover;
        }
      }
    }
  }
  .info {
    border: none;
    h3:after {
      display: none;
    }
  }
  .sidebar__item--tags {
    background-color: $color-light;
    h3{
      text-transform: uppercase;
      font-family: $font-headline-robot;
      font-weight: normal;
      font-size: 22px;
    }
  }
  .sidebar__item--read-also{
    margin: 10px;
  }
}
