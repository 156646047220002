.read-also {
  background: $color-ice;
  padding: $padding;
  clear: both;
  margin-bottom: $padding;

  h3{
    @extend .box-heading;
  }

  .read-also-item {
    @include clearfix;

    h4{ 
      font-size: 20px;
      a {
        color: $color-black;
      }
      margin: 0px;
      font-weight: 500;
    }

    img {
      height: auto;
      margin-top: $padding;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: $padding;
      .ingress {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      .info.with-image {
        width: 70%;
        box-sizing: border-box;
        float: left;
        padding-left: $padding;

      }

      img {
        width: 30%;
        height: auto;
        box-sizing: border-box;
        float: left;
      }
    }
  }
}