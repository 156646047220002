
//** COLORS **//
// Primary colors
$color-red: #D8203F;
$color-blue: #2497B6;
// Secondary colors
$color-yellow: #F9ED78;
$color-peach: #FFAC86;
$color-ice: #DDE8F0;
// The gray scale
$color-white: #FFFFFF;
$color-light: #F1F1F1;
$color-stone: #979797;
$color-blackish: #4A4A4A;
$color-black: #000; //#1D1B1B /*TODO: Check that the color is ok with Kate*/

$font-tiny: 13px;
$font-super-tiny: 11px;

$font-headline: 'eculid_flex', serif;
$font-headline-alt: 'acta', 'sans-serif';
$font-headline-robot: 'mr_robot', serif;
$font-body: 'portada', 'sans-serif';
$font-body-alt: 'eculid_flex', serif;

$padding: 20px;

// Bootstrap grid variables
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

// Grid settings
$grid-columns:      12;
$grid-gutter-width-base: 30px;
$grid-gutter-width: 1.875rem !default; // 30px

$grid-gutter-widths: (
  xs: $grid-gutter-width-base, // 30px
  sm: $grid-gutter-width-base, // 30px
  md: $grid-gutter-width-base, // 30px
  lg: $grid-gutter-width-base, // 30px
  xl: $grid-gutter-width-base  // 30px
);

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-width: 1060px;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
