address {
  font-style: normal; 
}

p{
  .intro {
    font-family: $font-headline;
    font-weight: bold;
    color: $color-blue;
  }
}

h2 {
  line-height: 1.2em;
  font-size: 20px;
  font-weight: 500;
  @include media-breakpoint-up(lg) {
      font-size: 42px;
  }
}
.box-heading{
  text-transform: inherit;
  font-family: $font-headline;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  margin: 0 0 10px 0;
  line-height: 1.3;
  font-size: 1.17em;
  @include media-breakpoint-up(md){
    font-size: 1.5em;
  }
}