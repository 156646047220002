.site-header__filters {
  position: fixed;
  width: 100%;
  z-index: 2;
  display: none;
  padding: 30px 0 70px 0;
  color: #fff;
  background: $color-red;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 200px);
  @include media-breakpoint-down(sm){
    max-height: calc(100vh - 200px);
  }

  .count {
    color: $color-red;
  }

  .filter-headline {
    margin-top: 0;
    color: #fff;
    font-size: 30px;
    margin-bottom: 20px;
    @include media-breakpoint-only(md) {
      position: relative;
      left: 28%;
      width: 44%;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0 0 40px 0;
    // @include admin-bar-fixed;
  }

  margin-top: -1px;
  @include media-breakpoint-up(md) {
    margin-top: 0px;
  }

  .wrap {
    margin: 0px auto;
    max-height: 100%;
    padding: 0 10px;
  }
  .filter-group {
    position: relative;
    @include unstyled-list();
    padding: 0px;
    padding-bottom: $padding;
    @include media-breakpoint-up(md) {
      width: 44%;
      margin: 0 auto;
    }
    @include media-breakpoint-up(lg) {
      width: 28%;
      float: left;
      padding: 0;
      padding-right: $padding;
      box-sizing: border-box;
       &:last-child {
        padding-right: 0px;
      }
      &.search {
        width: 14%;
      }
    }
    &.search {
      button {
        width: 100%;
        border: 2px solid $color-white;
      }
    }
    ul {
      position: relative;
      display: block;
      background: $color-white;
      padding: $padding;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;

      &.heading h3{
        border: none;
      }
      &.heading{
        z-index: 1;
        padding-top: 15px;
        padding-bottom: 5px;
        cursor: pointer;
        font-size: 14px;
        .arrow{
          @include transition(transform 0.5s);
          position: relative;
          float: right;
          width: 9px;
          top: 5px;
        }
      }
      &.expanded{
        position: relative;
        top: -1px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        // position: absolute;
        padding-top: 0;
        padding-bottom: 0;
        // visibility: hidden;
        display: none;
        overflow: hidden;
        @include transition(height 0.4s ease-in);
      }
      li {
        display: block;
        h3 {
          margin: 0px;
          padding-bottom: 10px;
          border-bottom: 1px solid #979797;
        }
        a {
          display: block;
          padding: 10px;
          position: relative;
          border-bottom: 1px solid #979797;
          font-family: $font-headline;
          color: $color-black;

          &[data-selected="true"] {
            font-weight: bold; 
            color: $color-red;
          }
        }
        &:last-child a{
          border-bottom: none;
        }
      }
    }
    &.trigger-state-going-down{
      ul.heading{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        .arrow{
          @include transform(rotateZ(90deg));
        }
      }
      ul.expanded{
        // visibility: visible;
        @include transition(height 0.4s ease-in);
      }
    }
    &.trigger-state-going-up{
      ul.heading{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      ul.expanded{
        // visibility: visible;
        @include transition(height 0.4s ease-in);
      }
    }
    &.trigger-state-down{
      ul.heading{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        .arrow{
          @include transform(rotateZ(90deg));
        }
      }
      ul.expanded{
        // visibility: visible;
        // height: 0;
        @include transition(height 0.4s ease-in);
      }
    }
  }
}

#main--filter {
  .filter-header{
    border-bottom: 1px solid $color-stone;
    margin-bottom: $padding*2;

    h1 {
      font-size: 30px;
      font-weight: 500;
    }

    p {
      @extend h3;
      font-size: 20px;
      font-weight: 500;

      .taxonomy {
        color: $color-blue;
      }
    }
  }
}
