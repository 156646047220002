.site-footer {
  margin-top: $padding*3;
  font-size: 14px;
  background: $color-light;
  font-family: $font-body-alt;
  overflow: hidden;

  .wrap {
    padding: 30px 0;
    @include clearfix();
    max-width: $container-max-width;
    margin: 0px auto;
  }

  .social-link {
    line-height: 46px;
    width: 46px;
    text-align: center;
    display: inline-block;
    background: $color-blackish;
    margin-right: round($padding/2);
    color: $color-white;
    font-size: 28px;
    border-radius: 50%;
  }
}

.site-footer__col {
  width: 100%;
  float: left;
  padding: 0 10px;
  box-sizing: border-box;

  @include media-breakpoint-up(sm) {
    width: 50%;
  }
  
  @include media-breakpoint-up(lg) {
    width: 25%;
  }
}

.site-footer__item {
  margin: 25px 0;

  h3 {
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 18px;

    &:after {
      display: block;
      content: "";
      width: 30px;
      height: 3px;
      margin: 5px 0 20px 0;
      background-color: $color-black;
    }
  }

  .nav {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
  }

  .union-logo {
    width: 115px;
    margin: 30px 0;
    display: block;
  }

  .unionpapers-logo {
    width: 220px;
    margin: 30px 0;
    display: block;

  }

  .colab-logo {
    display: block;
    margin: 30px 0;
    display: block;
    
  }
}

.site-footer__item--contact {
  [type=submit] {
    float: right;
  }
}

.site-footer__bootom-bar {
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: $font-tiny;
  text-align: center;
  background: $color-blackish;
}
