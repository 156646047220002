.archive {

}

.archive-meta {
  border-bottom: 1px solid $color-stone;
  padding-bottom: $padding;

  .filter {
    color: $color-blue;
  }
}

.search{
	h2{
		font-size: 22px;
		@include media-breakpoint-up(sm){
			font-size: 28px;
		}
	}
	.article{
		.entry-title{
			font-size: 24px;
			@include media-breakpoint-up(sm){
				font-size: 26px
			}
			font-weight: bold;
		}
		.article-meta{
			margin: 10px 0;
			@include media-breakpoint-up(sm){
				margin: 0;
			}
		}
		.col{
			position: relative;
			@include media-breakpoint-up(sm){
				float: left;
			}
		}
		.col-text{
			@include media-breakpoint-up(sm){
				left: -35%;
				width: 65%;
			}
		}
		.col-image{
			@include media-breakpoint-up(sm){
				left: 65%;
				width: calc(35% - 20px);
				margin-left: 20px;
				padding-top: 15px;
			}
		}
	}
	.page-wrap{
		position: relative;
		@include media-breakpoint-up(sm){
		.sidebar{
			position: absolute;
			top: 10px;
			right: 0px;
		}
		}
		@include media-breakpoint-up(md){
		.sidebar{
			top: 0;
		}
		}
	}
	.search-meta{
		padding: 10px 0;
		margin-bottom: 20px;
		&.borders{
			border-bottom: 1px solid $color-stone;
			.col-left{
				width: 100%;
				@include media-breakpoint-up(md){
					width: 50%;
				}
			}
			.col-right{
				width: 100%;
				margin-top: 5px;
				@include media-breakpoint-up(md){
					width: calc(50% - 2px);
					border-left: 1px solid $color-stone;
					margin-top: 0;
				}
			}
		}
		.col{
			float: left;
			width: 50%;

			color: #565656;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-family: $font-headline;
			font-weight: 500;
		}
		.col-left{
			min-height: 24px;
		}
		.col-right{
			text-align: right;
			.nav-links{
				position: relative;
				@include media-breakpoint-up(sm){
					padding-right: 30px;
				}
				padding-right: 20px;
				.page-numbers{
					margin: 0 10px;
					@include media-breakpoint-up(sm){
						margin: 0 6px;
					}
				}
				.arrow-link{
					position: absolute;
					top: 3px;
					@include span-arrow-base;
					background-color: $color-red;
				}
				.arrow-prev{
					background-image: url('../images/arrow-white.svg');
					@include transform(rotateZ(180deg));
					margin-left: -24px;
				}
				.arrow-next{
					right: 10px;
					@include media-breakpoint-up(sm){
						right: 0;
					}
					background-image: url('../images/arrow-white.svg');
				}
			}
		}
	}
}