.show-for-medium {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.hide-for-medium {
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }
}