/// Grid system
//

@mixin make-row($gutter: $grid-gutter-width) {
  @include clearfix();
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
}
@mixin make-col($gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-col-span($size, $columns: $grid-columns) {
  width: percentage($size / $columns);
}
@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}
@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}
@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}
@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.1.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-between($name, $name, $breakpoints) {
    @content;
  }
}

@mixin admin-bar-fixed {
  .admin-bar & {
    margin-top: 32px !important;

    @media screen and ( max-width: 782px ) {
      margin-top: 46px !important;
    }
  }
}

@mixin unstyled-list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
  }
}

@mixin cover-parent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

/**
 * Cross-browser transform mixin
 * @param string $t Entered without any type of quotes, e.g. @include transform(rotate(90deg) translate(50%));
 */
@mixin transform($t) {
  -webkit-transform:$t;
     -moz-transform:$t;
      -ms-transform:$t;
       -o-transform:$t;
          transform:$t;
}
/**
 * Cross-browser transition mixin
 * @param string $t Entered without any type of quotes, e.g. @include transform(rotate(90deg) translate(50%));
 */
@mixin transition($t) {
  -webkit-transition:$t;
     -moz-transition:$t;
      -ms-transition:$t;
       -o-transition:$t;
          transition:$t;
}

@mixin heading-after{
  &:after {
    display: block;
    content: "";
    width: 30px;
    height: 3px;
    background-color: $color-black;
    margin-top: 5px;
  }
}

@mixin span-arrow-base{
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7px 10px;
  transition: all 0.2s ease;
}