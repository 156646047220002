.wp_rp_wrap {
  padding: 20px;
  background-color: $color-ice;
}

.related-articles {
  font-family: $font-headline;
  font-weight: 500;
  line-height: 1.7;
  h3 {
    @extend .box-heading;
  }
}
