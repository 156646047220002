.site-header {
  @include clearfix();
  @include media-breakpoint-down(md) {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    background: $color-white;
    @include admin-bar-fixed();
  }
  z-index: 200;


  .site-header-inner {
    position: relative;
    background: $color-white;
    z-index: 1000;
  }

  .wrap {
    max-width: $container-max-width;
    margin: 0px auto;
  }

  @include media-breakpoint-down(lg) {
  .trigger-filters {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
  }
}

@include media-breakpoint-down(md) {
  .site-header__top-bar{
    border-bottom: 0px;
  }

  .tipsa-toggle{
    display: none;
  }

  .hamburger-toggle{
    display: block;
  }

  .search-toggle{
    float: none;
    position: absolute;
    top: 21px;
    left: 24px;
    margin: 0;
    width: 25px;
    height: 25px;
  }



  .site-header__logo{
    margin: 21px auto 22px auto;
    width: 300px;
  }

  .menu-huvudmeny-container{
    display: none;
  }

  .nav-secondary, .nav-extra{
    display: none;
  }

}

@include media-breakpoint-down(sm) {
  .site-header__logo{
    margin: 11px auto 12px auto;
    width: 143px;
  }

  .hamburger-toggle{
    top: 10px;
    right: 11px;
  }

  .search-toggle{
    top: 10px;
    left: 11px;
  }

  .trigger-filters {
    height: 30px;
  }
}
}

.site-header__top-bar {
  border-bottom: 1px solid $color-stone;
  background: $color-white;
  position: relative;
  z-index: 100;

  .nav-extra {
    float: left;
    .nav {
      margin: 0;
      padding-left: 0px;
      font-family: $font-headline;
      text-align: left;
      font-size: $font-super-tiny;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 14px 10px;
      }

      a {
        color: #565656; //*TODO: Check if correct with Kate*//
      }
    }
  }


  .nav-secondary{
    float: right;

    .nav {
      margin: 0;
      padding-left: 0px;
      font-family: $font-headline;
      text-align: right;
      font-size: $font-super-tiny;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 14px 10px;
      }

      a {
        color: #565656; //*TODO: Check if correct with Kate*//
      }
    }
  }
}

.hamburger-toggle,
.search-toggle,
.tipsa-toggle{
  margin: 14px 10px;
  border: 0;
  width: 16px;
  height: 16px;
  padding: 0;
  font-size: 100%;
  border-radius: 0;
  background: transparent;
  transition: none;

  &:hover{
    background: transparent;
  }

  img{
    width: auto;
    max-width: initial;

    @include media-breakpoint-down(md) {
      height: 25px;
    }
  }
}

.tipsa-toggle{
  float: left;
  width: auto;
  font-family: $font-headline;
  font-size: $font-super-tiny;
  color: #565656;
}

.search-toggle{
  float: right;
}

.dropdown-search{
  transition: top 0.3s;
  transition-timing-function: ease-out;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    @include admin-bar-fixed();
  }


  &.visible {
    top: 80px;

    padding-top: 40px;

    @include media-breakpoint-up(md) {
      top: 100px;
    }

    @include media-breakpoint-up(lg) {
      top: 210px;
    }
  }

  .close {
    position: absolute;
    top: $padding/2;
    right: $padding;
    background: none;
    color: $color-red;
    font-size: 30px;
    width: initial;
    padding: 0;
    height: initial;

    img {
      width: 15px;
      height: auto;
      color: $color-red;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: $padding*2 !important;
  }
}

.search-wrap {
  @include media-breakpoint-down(md) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px $padding;

    .search-field {
      width: 75% !important;
      float: left;
    }
    .search-submit {
      width: 20% !important;
      float: right;
      padding: 0px;
      text-align: center;
    }
  }


}

.dropdown-search,
.dropdown-tipsa {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -180px;
  background: $color-yellow;
  padding-bottom: 70px;
  z-index: 100;

  h3 {
    font-family: $font-headline-robot;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 1;
  }

  .search-wrap {
    max-width: 600px;
    margin: 0 auto;
  }

  .search-field {
    width: 438px;
    margin: 0;
    border: 1px solid $color-stone;
  }
  .search-submit {
    width: 138px;
    float: right;
  }
}

.dropdown-tipsa {
  display: none;
}

.hamburger-toggle{
  display: none;
  position: absolute;
  top: 21px;
  right: 24px;
  margin: 0;
  width: 25px;
  height: 19px;
}

.site-header__logo {
  display: block;
  margin: 26px auto 22px auto;
  width: 380px;
  line-height: 0;

  img{
    width: 100%;
    height: auto;
  }
}

.site-header__main-menu {
  background-color: $color-light;

  .nav-primary .nav {
    margin: 0 -10px;
    padding: 0;
    font-family: $font-headline;
    font-weight: normal;
    text-align: center;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 14px 10px;
      &.current_page_item, &.active{
        font-weight: bold;
      }
    }

    a {
      color: $color-black;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

//filter trigger button
.nav-primary {
  position: relative;
}

.nav-mobile{
  display: none;
  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 1000;
  font-family: $font-headline;
  background: $color-white;
  @include admin-bar-fixed();
  overflow: auto;

  @include media-breakpoint-up(md) {
    top: 100px;
  }


  .mobile-nav-open & {
    display: block;
  }

  #mobile-nav-part-1{
    border-top: 1px solid #979797;
  }

  #mobile-nav-part-2{
    margin-top: 35px;
  }

  ul{
    margin: 10px;
    padding: 0;
    list-style: none;

    li {
      a {
        display: block;
        padding: 11px 35px;
        position: relative;
        border-bottom: 1px solid #979797;
        &:after{
          display: block;
          position: absolute;
          top: 50%;
          right: 35px;
          width: 9px;
          height: 16px;
          content: "";
          background-image: url("../images/arrow-red.svg");
          background-repeat: no-repeat;
          background-position: top left;
          transform: translateY(-50%);
        }
      }


    }

  }

  a{
    color: black;
  }

  .tipsa-toggle-ul{
    margin: 36px 10px 10px 10px;
    li:after{
      display: none;
    }
  }

  .tipsa-toggle{
    display: block;
    float: none;
    margin: 10px 45px;
    height: auto;
    font-size: 16px;
    line-height: 1.5;
    color: #d8203f;
    font-weight: bold;
  }
}

.trigger-filters {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 0;
  padding: 0 20px 0 40px;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  font-family: $font-headline;
  letter-spacing: 1px;

  &:hover {
    background-color: $color-red;
  }

  span {
    @include span-arrow-base;
    margin: 0 0 -3px 35px;
    background-color: #fff;
    background-image: url('../images/arrow-red.svg');
  }
  &.triggered span {
    transform: rotate(90deg);
  }
}


.hamburger-toggle {
  $height: 20px;
  width: 30px;
  height: $height;
  margin-left: $padding/2;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  float: right;
  span {
    background: $color-black;
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2), span:nth-child(3) {
    top: $height/2;
  }

  span:nth-child(4) {
    top: $height;
  }

  .mobile-nav-open & span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .mobile-nav-open & span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .mobile-nav-open & span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mobile-nav-open & span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}
