.error404 {
  .main {
    margin: 0px auto;
    float: none;
    padding: $padding;

    text-align: center;
    

    @include media-breakpoint-up(lg) {
      padding: $padding 0px;
    }
  }

}