#catapult-cookie-bar {

  background: $color-white !important;
  text-align: center;
  padding: $padding/2;

  font-family: $font-headline;
  .ctcc-inner {
    display: inline-block;
  }

  #catapultCookie {
    margin-left: $padding;
    @extend .button--sm;
  }
}