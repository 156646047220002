.oppinion-header {

  .vignette {
    width: 100%;
    margin-top: 25px;
    border-top: $padding solid $color-yellow;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $font-headline;
    font-weight: 500;
    padding: 10px 0;
    color: $color-black;
    margin-bottom: 30px;
  }

  .article--feed & .entry-title{
    font-family: $font-headline;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 26px;
    @include media-breakpoint-up(md){
      margin-top: 0;
      font-size: 39px;
    }
  }

  .author-info {

  }

  h1 {
    font-size: 42px;
    font-family: $font-headline-alt;
    color: $color-black;
    margin-top: 0px;
  }

  .author-byline {
    font-size: 26px;
    @include media-breakpoint-up(md){
      font-size: 32px;
    }
    font-family: $font-headline;
    font-weight: 500;
    margin-bottom: 0;
    color: $color-black;

  }

}

.page-template-template-landing, .feed__item {
    .oppinion-header {

      .author-info {
        @extend .main;
      }

      .author-img {
        @extend .sidebar;

        padding-top: 0px;

        .img-container {
          position: relative;
          width: 100%;
          padding-bottom: 75%;
          background-size: cover;
          background-position: center center;
        }



        .photo-byline {
          margin-top: 10px;
          font-weight: 500;
          font-family: $font-headline;
          font-size: 11px;
          text-align: right;
          color: $color-black;
          text-transform: uppercase;
        }
      }

    }
}
