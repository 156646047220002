
//** CONTACT FORM "Fast and Secure Contact Form" **//
.fscf-div-form {
  #fscf_required1 {
    font-size: 10px;
  }

  .fscf-div-error {
    margin-top: 7px;
    font-size: $font-tiny;
    color: $color-red;

    &#fscf_form_error1 {
      margin: 15px 0 10px 0;
      padding: 7px 10px;
      color: $color-black;
      border-radius: 5px;
      background-color: $color-peach;
    }
  }
  .fscf-div-submit {
    text-align: right;
  }
}

.wpcf7 {
  .wpcf7-not-valid {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  .wpcf7-not-valid-tip {
    font-size: $font-tiny;
    color: $color-red;
    margin-bottom: 10px;
  }

  .wpcf7-response-output {
    margin: 25px 0 10px 0 !important;
    padding: 7px 10px;
    color: $color-black;
    border-radius: 5px;
    font-size: $font-tiny;
    border: none;
    display: inline-block;

    &.wpcf7-mail-sent-ng,
    &.wpcf7-validation-errors{
      background-color: $color-peach;
    }

    &.wpcf7-mail-sent-ok {
      background-color: $color-ice;
    }

    &.wpcf7-display-none {
      display: none;
    }
  }

  .ajax-loader {
    display: none !important;
  }

  .align-right {
    text-align: right;

    .wpcf7-submit {
      display: inline-block;
    }
  }
}

.site-footer {
  .wpcf7-response-output {
    float: left;
  }
}


input,
textarea {
  width: 100%;
  height: 40px;
  margin: 5px 0 15px;
  padding: 0 10px;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: $font-body-alt;
}

textarea {
  width: 100%;
  height: 138px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

label {
  font-family: $font-headline;
  color: $color-blackish;
  font-size: 14px;
}
