// Global page wrapper
.page-wrap {
  @include clearfix();
  max-width: $container-max-width;
  margin: 0 auto;
  margin-top: 80px;

  @include media-breakpoint-down(sm) {
      &.mobile-img {
          margin-top: 0;
      }
  }

  @include media-breakpoint-only(md) {
      &.desktop-img {
          margin-top: 0;
      }
  }

  @include media-breakpoint-up(md) {
    margin-top: 140px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0px;
  }
}

.wrap {
  @include clearfix();
}

.main {
  @include clearfix();
  float: left;
  padding: 0 $padding/2;
  box-sizing: border-box;
  margin-top: 20px;

  @include media-breakpoint-up(sm) {
    width: 66%;
  }
  @include media-breakpoint-up(md) {
    width: 66%;
  }
  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  &#main--landing, &#main--frontpage, &#main--feed, &#main--filter {
    padding: 0px;
    @include media-breakpoint-up(sm) {
      border-right: 1px solid $color-stone;
    }
  }
}

.sidebar {
  width: 100%;
  float: left;
  padding: 0;
  box-sizing: border-box;

  @include media-breakpoint-up(sm) {
    width: 34%;
  }
  @include media-breakpoint-up(md) {
    width: 34%;
  }
}
