html, body {
    max-width: 100%;
    overflow-x: hidden;
    &.no-scroll{
      overflow-y: hidden;
    }
}

body {
  color: $color-blackish;
  line-height: 1.5;
  font-family: $font-body;
}

// Some browsers have issues with <strong> and <b> tags
strong, b {
    font-weight: bold;
}

a {
  color: $color-red;
  text-decoration: none;
}

//** Global typo **//

h1,
h2,
h3,
h4
{
  color: $color-black;
  font-family: $font-headline;
  word-wrap: break-word;
}

* {
  outline: 0;
}


.invisible,
.screen-reader-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


img {
  max-width: 100%;
}

#wpadminbar {
  position: fixed;
}

::selection { background: $color-ice; }

//** FONTS **//
@font-face {
  font-family: 'portada';
  src: url('../fonts/Portada-Regular.eot');
  src: url('../fonts/Portada-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Portada-Regular.woff2') format('woff2'),
  url('../fonts/Portada-Regular.woff') format('woff'),
  url('../fonts/Portada-Regular.svg#Portada-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'portada';
  src: url('../fonts/Portada-Bold.eot');
  src: url('../fonts/Portada-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Portada-Bold.woff2') format('woff2'),
  url('../fonts/Portada-Bold.woff') format('woff'),
  url('../fonts/Portada-Bold.svg#Portada-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'eculid_flex';
  src: url('../fonts/EuclidFlex-Regular-WebS.eot');
  src: url('../fonts/EuclidFlex-Regular-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidFlex-Regular-WebS.woff2') format('woff2'),
  url('../fonts/EuclidFlex-Regular-WebS.woff') format('woff'),
  url('../fonts/EuclidFlex-Regular-WebS.svg#EuclidFlex-Bold-WebS') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'eculid_flex';
  src: url('../fonts/EuclidFlex-Medium-WebS.eot');
  src: url('../fonts/EuclidFlex-Medium-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidFlex-Medium-WebS.woff2') format('woff2'),
  url('../fonts/EuclidFlex-Medium-WebS.woff') format('woff'),
  url('../fonts/EuclidFlex-Medium-WebS.svg#EuclidFlex-Medium-WebS') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'eculid_flex';
  src: url('../fonts/EuclidFlex-Bold-WebS.eot');
  src: url('../fonts/EuclidFlex-Bold-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidFlex-Bold-WebS.woff2') format('woff2'),
  url('../fonts/EuclidFlex-Bold-WebS.woff') format('woff'),
  url('../fonts/EuclidFlex-Bold-WebS.svg#EuclidFlex-Bold-WebS') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'acta';
  src: url('../fonts/ActaHeadline-Book.eot');
  src: url('../fonts/ActaHeadline-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ActaHeadline-Book.woff2') format('woff2'),
  url('../fonts/ActaHeadline-Book.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'mr_robot';
  src: url('../fonts/mr_robot-webfont.eot');
  src: url('../fonts/mr_robot-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/mr_robot-webfont.woff2') format('woff2'),
  url('../fonts/mr_robot-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$sm: breakpoint-min(sm);
$md: breakpoint-min(md);
$lg: breakpoint-min(lg);

.hide-for-small-only {
  @media (max-width: $sm) {
    display: none !important;
  }
}
.show-for-small-only {
  @media (min-width: $sm) {
    display: none !important;
  }
}

.hide-for-medium-up {
    @media (min-width: $md) {
        display: none !important;
    }
}

.show-for-medium-only {
    @media (max-width: $md) {
        display: none !important;
    }

    @media (min-width: $lg) {
        display: none !important;
    }
}

.hide-for-large {
    @media (min-width: $lg) {
      display: none !important;
    }
}

.show-for-large {
    @media (max-width: $lg) {
      display: none !important;
    }
}

.clearfix{
  clear: both;
}

.ad {
    .ad-headline{
        margin-bottom: 5px;
        font-family: $font-headline;
        font-size: $font-super-tiny;
        font-weight: 500;
    }

    &.header-ad {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-down(md) {
            margin-top: 110px;
        }

        @include media-breakpoint-only(md) {
            margin-top: 170px;
        }

        .ad-wrap {
            text-align: left;
            display: inline-block;
            margin-bottom: 26px;
            width: 100%;
        }
    }

    &.ad-980x240 {
        .ad-wrap {
            max-width: 980px;

            img {
                max-width: 980px;
                width: 100%;
                max-height: 240px;
            }
        }
    }

    &.ad-320x320 {
        .ad-wrap {
            max-width: 320px;

            img {
                max-width: 320px;
                width: 100%;
                max-height: 320px;
            }
        }
    }
}
