.asikter{
	.page-title{
		margin-bottom: 10px;
	}
	.group-title{
		margin-top: 40px;
		font-size: 32px;
		font-weight: 600;
	}
	.ingress{
		margin-top: 10px;
		font-weight: 500;
	}
	.question{
		header{
			.question-title{
				font-weight: 500;
			}
		}
	}
	.more-questions-button{
		padding-top: 40px;
		display: block;
		text-align: center;
		border-top: 1px solid $color-stone;
	}
	.more-questions{
		display: none;
	}

	@include media-breakpoint-up(sm){
	.group-title{
		margin-top: 50px;
	}
	.more-questions-button{
		display: none;
	}
	.more-questions{
		display: block;
	}
	}

	.question{
		border-top: 1px solid $color-stone;
		&:last-child{
			border-bottom: 1px solid $color-stone;
		}
		padding-left: 20px;
		&.closed{
			.arrow{
				@include transition(all 0.25s ease-in);
			}
			.cross{
				display: none;
			}
			main{
				display: none;
			}
		}
		&.opening{
			.arrow{
				opacity: 0;
			}
			.cross{
				@include transition(all 0.25s ease-in);
				display: block;
				opacity: 1;
			}
		}
		&.opened{
			.arrow{
				display: none;
			}
			.cross{
				opacity: 1;
				display: block;
			}
		}
		&.closing{
			.cross{
				display: block;
				opacity: 0;
			}
		}
		header{
			position: relative;
			cursor: pointer;
			.interact{
				position: absolute;
				top: 5px;
				right: 20px;
			}
			.arrow{
				width: 12px;
				margin-right: 8px;
			}
			.cross{
				width: 20px;
			}
			.question-title{
				width: 80%;
			}
		}
		main{
			position: relative;
			.question-icon{
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
			}
			.question-body{
				margin-left: 50px;
				margin-bottom: 20px;
			}
			.author{
				margin-top: 10px;
			}
			.pre, .author{
				font-family: $font-headline;
				font-weight: bold;
				color: $color-black;
			}
			.question-answer{
				padding-bottom: 20px;
				.pre{
					color: $color-blue;
					float: left;
					margin-right: 5px;
					margin-top: -2px;
				}
			}
		}
	}
}