
[type=reset],
[type=submit],
button,
[type=button],
.button {
  width: auto;
  height: 40px;
  padding: 5px 46px;
  margin: 0;
  font-size: 20px;
  color: white;
  background: $color-red;
  border-radius: 100px;
  cursor: pointer;
  transition: all 500ms ease;
  border: none;
  font-family: $font-headline;
  font-weight: 500;

  &:hover {
    background:darken($color-red, 4%);
  }
}

.button--sm {
  font-size: 15px;
  line-height: 20px;
  padding: 0px 20px;
}

.button--lg {
  height: 50px;
}

.button--cookie {
  width: auto;
}

.pix-load-more img {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
}

// TODO: find better location?

.expandmore__button {
  background: none;
  font-size: inherit;
  color: inherit;
}
/* optional */
.expandmore__button:before,
.expandmore__button:before {
  content : '+ ';
}
.expandmore__button[aria-expanded=true]:before,
.expandmore__button[data-expanded=true]:before {
  content : '- ';
}
