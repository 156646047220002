.kontakta-oss{
	.contact-header{
		padding: 10px;
		.page-title{
			@include media-breakpoint-down(sm){
				font-size: 24px;
			}
		}
	}
	.main{
		@include media-breakpoint-only(xs){
			width: 100%;
			padding-right: 10px;
		}
		@include media-breakpoint-only(sm){
			margin-top: 10px;
		}
		padding-right: 0;
		.contact-modal {
			background-color: $color-ice;
			padding: 20px;
			font-family: $font-headline;
			position: relative;
			@include media-breakpoint-up(sm){
				min-height: 350px;
			}
			.heading{
				margin-top: 0;
				font-weight: normal;
				@include heading-after;
			}
			.map-link-w{
				text-align: center;
				width: 100%;
				margin-top: 80px;
				@include media-breakpoint-up(sm){
					margin-top: 0;
					left: 50%;
					@include transform(translateX(-50%));
					position: absolute;
					bottom: 20px;
				}
			}
		}
	}
	.redaktionen{
		float: left;
		margin: 10px;
		width: calc(100% - 20px);
		@include media-breakpoint-up(md){
			margin: 0;
			width: 100%;
		}
		.heading{
			font-weight: 600;
			font-size: 24px;
			@include media-breakpoint-up(sm){
				font-size: 32px;
			}
		}
		.contact-item{
			width: 100%;
			background-color: $color-ice;
			margin-bottom: 10px;
			@include media-breakpoint-up(sm){
				width: calc(33% - 5px);
				margin-right: 10px;
				float: left;
				&:nth-child(3n+1){ // Selects element 3,6,9,.. , where first index is 1
					margin-right: 0;
				}
			}
			@include media-breakpoint-up(lg){
				width: calc(33% - 10px);
				margin-right: 20px;
				&:nth-child(3n+1){ // Selects element 3,6,9,.. , where first index is 1
					margin-left: -1px;
				}
			}
			img{
				width: 100%;
				height: 40vh;
				object-fit: cover;
				@include media-breakpoint-up(sm){
					height: 200px;
				}
				@include media-breakpoint-up(md){
					height: 250px;
				}
				@include media-breakpoint-up(lg){
					height: 300px;
				}
			}
			.info-w{
				font-family: $font-headline;
				font-weight: 500;
				font-size: 18px;
				padding: 10px;
				color: $color-black;
				h3{
					@include heading-after;
					font-size: 18px;
					font-weight: 500;
					margin: 0 0 10px 0;
				}
			}
		}
	}
}
