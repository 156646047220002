.page{
  .page-title {
    font-size: 42px;
    font-weight: 500;
  }
  .ingress {
    font-weight: bold;
    font-family: $font-headline;
    font-size: 20px;
    color: $color-black;
  }
}
