.related-articles {
  background: $color-light;
  padding: $padding;
  clear: both;
  margin-bottom: $padding;

  ul {
    margin: 0px;
    padding: 0px;
    padding-left: $padding;
    list-style: none;
    list-style-image-size: 25px;
    li {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: -20px;
        width: 2em;
        height: .9em;
        top: 0.4em;
        background: url('../images/arrow-red.svg') left center no-repeat;
        background-size: contain;
      }
    }
  }
}