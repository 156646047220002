.article--feed {
  margin-right: 10px;
  margin-left: 10px;
  min-height: 500px;

  @include media-breakpoint-up(lg) {
    margin-right: 20px;
    margin-left: 0;
  }

  .vignette {
    margin-bottom: 20px;
    padding: 12px 20px;
    color: white;
    font-family: $font-headline;
    font-weight: 500;
    letter-spacing: 2px;
    background-color: $color-blue;
    box-sizing: border-box;
  }

  h2 {
    margin: 10px 0 0 0;

    a {
      color: $color-black;
    }
  }

  p {
    margin-top: 7px;
    font-size: 16px;

    .intro {
      font-family: $font-headline;
      font-weight: bold;
      color: $color-blue;
    }
  }
}

.your-oppinion {
  font-family: $font-headline;
  font-weight: bold;
  font-size: 20px;
  color: $color-black;
  padding-bottom: $padding;
  border-bottom: 1px solid $color-stone;
  margin-bottom: $padding;
  
}

.feed__item {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid $color-stone;

  img {
    min-width: 100%;
    height: auto;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .link {
    @include cover-parent();
  }
}

.article-meta {
  font-family: $font-headline;
  font-weight: 500;
  font-size: $font-super-tiny;
  text-transform: uppercase;
  color: $color-black;
}

// article-packages TODO: move to better location
.article-package {
  margin-bottom: 20px;
  background-color: $color-ice;
  border: none;
  padding: 0;
  > div {
    padding: 5px;
    @include clearfix();
    @include media-breakpoint-up(md) {
      padding: 10px;
    }
  }
  .vignette {
    margin-bottom: 0;
    width: 100%;
  }
  .related-articles {
    padding: 20px;
  }
}
.article_package__item {
  @include clearfix();
  box-sizing: border-box;
  img {
    max-width: 100%;
  }

  &.big {
    width: 100%;
    padding: 10px;
    .img-wrap {
      width: 100%;
    }
  }
  &.medium {
    @include clearfix();
    width: 100%;


    h2 {
      font-size: 25px;
      line-height: 29px;
    }
    .img-wrap {
      width: 50%;
      float: left;
      padding: 10px;
      box-sizing: border-box;
      line-height: 0;
    }
    .content-wrap {
      width: 50%;
      float: left;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  &.small {
    padding: 10px;
    
    @include media-breakpoint-down(sm) {
      .img-wrap {
        width: 50%;
        float: left;
        padding-right: 15px;
        box-sizing: border-box;
        line-height: 0;
      }
      .content-wrap.with-image {
        width: 50%;
        float: left;
        box-sizing: border-box;
      }
    }

    @include media-breakpoint-up(md) {
      width: 50%;
      float: left;
      h2 {
        font-size: 25px;
        line-height: 29px;
        font-weight: bold;
      }
    }

    

    .img-wrap {

    }
    .content-wrap {

    }
  }
}
