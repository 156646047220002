.article {

  .vignette {
    margin-bottom: 20px;
    padding: 12px 20px;
    color: white;
    font-family: $font-headline;
    font-weight: bold;
    letter-spacing: 2px;
    background-color: $color-blue;
    box-sizing: border-box;
    display: inline-block;
    text-transform: uppercase;
  }

  .social-media {
    list-style: none;
    margin: 0px;
    margin-bottom: $padding*2;
    padding: 0px;
    text-align: right;
    float: right;
    display: block;
    li {
      display: inline-block;
      margin-left: $padding;
    }
    a, span {
      font-family: $font-headline;
      color: $color-blackish;
      font-size: 15px;
      font-weight: bold;
      line-height: 40px;
    }
    .fa {
      width: 40px;
      line-height: 40px;
      border-radius: 50%;
      background: $color-blackish;
      color: $color-white;
      font-size: 20px;
      text-align: center;
      margin-right: 5px;
    }
  }

  .entry-title {
    @extend h2;
    margin: 0px 0 15px 0;

    a {
      color: $color-black;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  .img-wrapper {
    position: relative;
    @include media-breakpoint-up(sm) {
      margin-top: 10px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    .article-img {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
      vertical-align: top;
      max-width: initial;
      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 100%;
        margin-left: initial;
        margin-right: initial;
      }
    }

    .caption {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      background: rgba(0,0,0, 0.5);
      padding: 10px;
      color: $color-white;
      line-height: 1.1em;
      font-family: $font-headline;
      font-size: 13px;
    }
    .caption-toggle{
      display: none;
      &.visible{
        display: block;
      }
    }
    .caption-toggle-button-close-container{
      left: -10px;
      right: -10px;
      @include media-breakpoint-up(md) {
        left: 0;
        right: 0;
      }
      .button-close{
        text-align: right;
        margin-bottom: 10px;
      }
    }
    .caption-toggle-button-open-container{
      right: inherit;
      left: 0;
      @include media-breakpoint-up(md) {
        left: 10px;
        bottom: 1px;
        padding: 12px;
      }
      img{
        @include transform(rotate(45deg));
      }
    }
    .caption-toggle-button{
      cursor: pointer;
      width: 15px;
      @include media-breakpoint-up(md) {
        width: 17px;
      }
    }
  }

  .article-intro {
    margin-bottom: 30px;
    font-family: $font-headline;
    line-height: 1.45em;
    font-size: 15px;
    font-weight: bold;
    color: $color-black;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  .entry-content-asset iframe{
    max-width: 100%;
  }

  .byline {
    font-family: $font-headline;
    font-weight: 500;
    margin: $padding 0px;
    .name {
      color: $color-red;
      font-size: 18px;
    }
    .phone {
      font-size: 16px;
      color: $color-black;
    }
  }

  .photo-meta{
    .article-meta{
      float: left;
      margin-right: 40px;
    }
  }
  .photo-byline {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: $font-headline;
    font-size: 11px;
    text-align: right;
    color: $color-black;
    text-transform: uppercase;
  }

  p {
    margin-top: 10px;
    margin-bottom: 15px;

    strong {
      font-weight: 600;
    }
    .intro {
      font-family: $font-headline;
      font-weight: bold;
      color: $color-blue;
    }
  }

  blockquote {
    width: 40%;
    float: left;
    font-size: 30px;
    line-height: 35px;
    color: $color-blue;
    font-family: $font-headline-alt;
    font-weight: bold;

    border-top: 1px solid $color-stone;

    &.align-left {
      float: left;
      margin: 30px 30px 30px 0;
    }

    &.align-right {
      float: right;
      margin: 30px 00px 30px 30px;
    }

    footer {
      font-size: 11px;
      color: $color-black;
      text-align: right;
      font-family: $font-headline;
    }

  }

  figure {
    margin-left: 0;
    margin-top: 4px;
    @include media-breakpoint-down(xs){
      width: initial!important;
      margin-right: 0;
      img{
        object-fit: cover;
      }
    }
    &.alignleft{
      @include media-breakpoint-up(xs){
        margin-right: 40px!important;
      }
    }
    &.aligncenter{
      @include media-breakpoint-up(xs){
        margin-right: auto;
        margin-left: auto;
      }
    }
    &.alignright{
      @include media-breakpoint-up(xs){
        margin-left: 40px!important;
        margin-right: 0;
      }
    }
    figcaption {
      font-family: eculid_flex, sans-serif;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    img.alignleft,
    img.alignright {
      max-width: 40%;
    }
  }
}

.article--single {
  h2:not(.entry-title),
  h3{
    font-size: 15px;
    font-weight: bold;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
  footer h3{
    @extend .box-heading;
  }
  @include media-breakpoint-up(lg) {
    margin: 0px;
    margin-right: 20px;

    .article-meta {
      margin-bottom: $padding;
    }
  }
}
.extra-article{
  h1{
    line-height: 1.2em;
    font-weight: 500;
    margin: 80px 0 15px;
    font-size: 20px;
    @include media-breakpoint-up(lg) {
      font-size: 42px;
    }
  }
  footer{
    margin-top: 60px;
  }
}


.main-article {

    .author-img {
          width: 100%;
          padding-top: 0px;

          @include media-breakpoint-down(md) {
              text-align: center;
          }

          @include media-breakpoint-up(lg) {
              text-align: right;
          }
        .img-container {
          position: relative;

          width: calc(100% + 20px);
          margin-left: -10px;
          margin-right: -10px;

          @include media-breakpoint-up(sm) {
              width: calc(100% - 20px);
              display: inline-block;
              margin: 0;
          }

          padding-bottom: 75%;
          background-size: cover;
          background-position: center center;
        }

        .photo-byline {
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 500;
          font-family: $font-headline;
          font-size: 11px;
          text-align: right;
          color: $color-black;
          text-transform: uppercase;
        }
    }
  .main{
    margin-top: 0;

    .author-img {
      float: left;
    }
  }
  position: relative;
  @include clearfix;
  @include media-breakpoint-up(md) {
    border-bottom: 1px solid $color-stone;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: $padding*2;
    &:before {
      content: "";
      height: 1px;
      position: absolute;
      left: $padding/2;
      right: $padding/2;
      bottom: -$padding;
      background: $color-stone;
    }
  }

  @include clearfix;


  .author-info {
    width: 100%;
    float: left;

    .author-byline {
      font-size: 26px;
      @include media-breakpoint-up(md){
        font-size: 32px;
      }
      font-family: $font-headline;
      font-weight: 500;
      margin-bottom: 0;
      color: $color-black;

    }


    h1 {
      font-size: 42px;
      font-family: $font-headline-alt;
      color: $color-black;
      margin-top: 0px;
    }
  }
}
